import React from "react";
import { Grid, Typography } from "@mui/material";
import {
	useRedirectToAddressChoice,
	useRedirectToBillingChoice,
	useRedirectToCountryChoice,
	useRedirectToDeliveryFormat,
	useRedirectToLanding,
	useRedirectToPayment,
	useRedirectToSubscriptionChoice,
	useRedirectToTip,
} from "../hooks/subscription";
import { useLogUserIn, useRedirectToLogin } from "../hooks/auth";
import { useIsMobile, useIsTablet } from "../hooks/responsive";
import { useSelector } from "react-redux";
import { getAuthToken, getInreesId } from "../store/reducers/authSlice";
import { getOrder } from "../store/reducers/subscribeSlice";
import { useGetSepaQuery } from "../store/api/user";
import { useLazySetRenouvellementQuery } from "../store/api/subscribeTunnel";
import InexCard from "../components/InexCard";
import InexButton from "../components/InexButton";

const PaymentValidation = () => {
	useRedirectToLanding();
	useRedirectToCountryChoice();
	useRedirectToSubscriptionChoice();
	useRedirectToBillingChoice();
	useRedirectToDeliveryFormat();
	useRedirectToAddressChoice();
	useRedirectToTip();
	useRedirectToPayment();

	useRedirectToLogin();
	useLogUserIn();

	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const authToken = useSelector(getAuthToken);
	const inreesId = useSelector(getInreesId);

	const order = useSelector(getOrder);

	const [hasSetTypePaiement, setHasSetTypePaiement] = React.useState(false);
	const { data: sepaData } = useGetSepaQuery({
		authToken,
		idinexplore: inreesId,
	});

	const [setTypePaiement] = useLazySetRenouvellementQuery();

	React.useEffect(() => {
		if (hasSetTypePaiement === true) return;
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const hasSepa = sepaData?.sepas?.length > 0;
		if (urlParams.has("withSEPA") && hasSepa === true) {
			setTypePaiement({
				authToken,
				idinexplore: inreesId,
				order,
				typepaiement: 3,
			}).then(({ data }) => {
				setHasSetTypePaiement(true);
			});
		}
	}, [sepaData]);

	return (
		<Grid container px={isTablet ? 1 : 6} py={isMobile ? 3 : 4} pb={10}>
			<Grid container px={isMobile ? 1 : isTablet ? 5 : 15} pt={isMobile ? 2 : 5}>
				<InexCard
					sx={{
						paddingLeft: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
						paddingRight: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
					}}
				>
					<Grid container>
						<Grid item xs={12}>
							<Typography
								variant={"h4"}
								textAlign={"center"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding Bold",
									fontSize: 24,
									marginBottom: (theme) => theme.spacing(5),
								}}
							>
								{"Félicitations, votre commande est validée !"}
							</Typography>
							<Typography
								variant={"h4"}
								textAlign={"center"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding Bold",
									fontSize: 20,
									marginBottom: (theme) => theme.spacing(2),
								}}
							>
								{
									"Vous pouvez dès à présent profiter des contenus Inexploré sur le web et sur nos applications mobiles."
								}
							</Typography>
						</Grid>
						{/*<Grid item xs={12} lg={6} justifyContent={isMobile ? "space-between" : "center"} alignItems={"center"} display={"flex"} pt={3} pl={2} pr={2}>*/}
						{/*	<img*/}
						{/*		src={inexploréLogo}*/}
						{/*		alt={"inxploré-logo"}*/}
						{/*		style={{*/}
						{/*			width: isMobile ? "150px" : undefined,*/}
						{/*		}}*/}
						{/*	/>*/}
						{/*	<Box>*/}
						{/*		<IconButton*/}
						{/*			onClick={() => {*/}
						{/*				window.location.href = "https://apps.apple.com/fr/app/inexplor%C3%A9/id1619523355";*/}
						{/*			}}*/}
						{/*			size={"medium"}*/}
						{/*			sx={{*/}
						{/*				marginLeft: (theme) => theme.spacing(2),*/}
						{/*			}}*/}
						{/*		>*/}
						{/*			<img src={appStoreLogo1} alt={"appStoreLogo1"} />*/}
						{/*		</IconButton>*/}
						{/*		<IconButton*/}
						{/*			onClick={() => {*/}
						{/*				window.location.href = "https://play.google.com/store/apps/details?id=com.mobinesxplore";*/}
						{/*			}}*/}
						{/*			size={"medium"}*/}
						{/*			sx={{*/}
						{/*				marginLeft: (theme) => theme.spacing(1),*/}
						{/*			}}*/}
						{/*		>*/}
						{/*			<img src={playStoreLogo1} alt={"playStoreLogo1"} />*/}
						{/*		</IconButton>*/}
						{/*	</Box>*/}
						{/*</Grid>*/}
						{/*<Grid item xs={12} lg={6} justifyContent={isMobile ? "space-between" : "center"} alignItems={"center"} display={"flex"} pt={3} pl={2} pr={2}>*/}
						{/*	<img*/}
						{/*		src={inexploréTVLogo}*/}
						{/*		alt={"inxploré-logo"}*/}
						{/*		style={{*/}
						{/*			width: isMobile ? "180px" : undefined,*/}
						{/*		}}*/}
						{/*	/>*/}
						{/*	<Box>*/}
						{/*		<IconButton*/}
						{/*			onClick={() => {*/}
						{/*				window.location.href = "https://apps.apple.com/fr/app/inrees-tv/id1622540515";*/}
						{/*			}}*/}
						{/*			size={"medium"}*/}
						{/*			sx={{*/}
						{/*				marginLeft: (theme) => theme.spacing(2),*/}
						{/*			}}*/}
						{/*		>*/}
						{/*			<img src={appStoreLogo2} alt={"appStoreLogo2"} />*/}
						{/*		</IconButton>*/}
						{/*		<IconButton*/}
						{/*			onClick={() => {*/}
						{/*				window.location.href = "https://play.google.com/store/apps/details?id=com.inreestvapp";*/}
						{/*			}}*/}
						{/*			size={"medium"}*/}
						{/*			sx={{*/}
						{/*				marginLeft: (theme) => theme.spacing(1),*/}
						{/*			}}*/}
						{/*		>*/}
						{/*			<img src={playStoreLogo2} alt={"playStoreLogo2"} />*/}
						{/*		</IconButton>*/}
						{/*	</Box>*/}
						{/*</Grid>*/}
						<Grid iten xs={0} sm={0} md={1} lg={3} />
						<Grid item xs={12} sm={12} md={10} lg={6}>
							<InexButton
								text={"Aller sur mon profil"}
								onClick={() => {
									window.location.href = "https://my.inexplore.com/profil";
								}}
								sx={{
									marginTop: (theme) => theme.spacing(5),
									width: "100%",
									height: "50px",
								}}
							/>
						</Grid>
						<Grid iten xs={0} sm={0} md={1} lg={3} />
					</Grid>
				</InexCard>
			</Grid>
		</Grid>
	);
};

export default PaymentValidation;
