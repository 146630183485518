import React from "react";
import {
	Autocomplete,
	Box,
	CircularProgress,
	IconButton,
	styled,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import dropdown_icon from "../resources/images/svg/dropdown_arrow.svg";
import dropdownIconDisabled from "../resources/images/svg/dropdown_arrow_disabled.svg";

const StyledAutoComplete = styled(Autocomplete, {
	shouldForwardProp: (prop) => !["InputLabelProps"].includes(prop),
})(({ theme }) => {
	return {
		border: "1px solid #e2e2e1",
		borderRadius: "8px",
		fontFamily: "Branding SemiBold",

		overflow: "hidden",
		transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),

		"&:hover": {
			borderColor: theme.palette.primary.main,
		},
		"&:focus": {
			borderColor: theme.palette.primary.main,
			backgroundColor: "transparent",
		},

		input: {
			color: theme.palette.secondary.main,
		},
		color: theme.palette.secondary.main,
		".MuiAutocomplete-inputRoot:hover > fieldset": {
			borderColor: theme.palette.primary.main,
		},
		".MuiInputBase-root": {
			backgroundColor: "transparent",
		},
		".MuiInputBase-root:hover": {
			backgroundColor: "transparent",
		},
		".MuiAutocomplete-inputRoot::before": {
			display: "none",
		},
		".MuiAutocomplete-root:hover > label": {
			transform: "translate(14px, -5px) scale(0.75)",
		},
		"& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
			color: theme.palette.secondary.main,
		},
	};
});

const InexAutoComplete = ({
	label = "Label",
	options = [],
	loading = false,
	loadingText,
	disabled = false,
	error = false,
	helperText,
	...props
}) => {
	const theme = useTheme();

	return (
		<>
			<StyledAutoComplete
				renderInput={(params) => (
					<TextField
						{...params}
						variant={"filled"}
						label={label}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												minHeight: "100%",
												position: "absolute",
												right: "50px",
												top: 0,
											}}
										>
											<CircularProgress color="primary" size={20} />
										</Box>
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
				loadingText={loadingText ? loadingText : "Chargement..."}
				options={options}
				InputLabelProps={{
					sx: {
						transform: "translate(32px, 24px) scale(1)",
					},
				}}
				noOptionsText={"Aucun résultat"}
				popupIcon={
					<Box
						sx={{
							marginRight: theme.spacing(1),
							marginLeft: theme.spacing(1),
							width: "9px",
							height: "25px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<img src={disabled ? dropdownIconDisabled : dropdown_icon} alt={"dropdown_icon"} />
					</Box>
				}
				loading={loading}
				disabled={disabled}
				{...props}
			/>
			{helperText && (
				<Typography noWrap variant={"caption"} color={error ? "error" : "secondary"} pl={1} maxWidth={"100%"}>
					{helperText}
				</Typography>
			)}
		</>
	);
};

export default InexAutoComplete;
