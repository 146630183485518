import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";

export const useIsMobile = () => {
	const theme = useTheme();
	return useMediaQuery(theme.breakpoints.down("sm"));
};

export const useIsTablet = () => {
	const theme = useTheme();
	return useMediaQuery(theme.breakpoints.down("md"));
};

export const useIsLarge = () => {
	const theme = useTheme();
	return useMediaQuery(theme.breakpoints.up("xl"));
};
