import React from "react";
import { createBrowserRouter, RouterProvider, useLocation, Outlet } from "react-router-dom";
import Landing from "./Landing";
import CountryChoice from "./CountryChoice";
import SubscriptionChoice from "./SubscriptionChoice";
import BillingChoice from "./BillingChoice";
import AddressChoice from "./AddressChoice";
import TipChoice from "./TipChoice";
import Payment from "./Payment";
import PaymentSuccess from "./PaymentSuccess";
import PaymentPending from "./PaymentPending";
import PaymentError from "./PaymentError";
import PaymentValidation from "./PaymentValidation";
import DeliveryFormat from "./DeliveryFormat";
import ActivateGift from "./ActivateGift";
import CheckSending from "./CheckSending";
import AlreadySubscribed from "./AlreadySubscribed";
import JeMabonne from "./JeMabonne";
import Offrir from "./Offrir";
import PayOrder from "./PayOrder";
import RedirectMobile from "./RedirectMobile";
import GoogleTag from "src/components/tracking/GoogleTag";

const ErrorPage = () => {
	window.location.href = "/";

	return null;
};

export const routesDefinitions = {
	LANDING: {
		stepNb: null,
		routerObj: {
			path: "/",
			element: <Landing />,
			errorElement: <ErrorPage />,
		},
	},
	COUNTRY_CHOICE: {
		stepNb: 0,
		routerObj: {
			path: "/step/country",
			element: <CountryChoice />,
		},
	},
	SUBSCRIPTION_CHOICE: {
		stepNb: 1,
		routerObj: {
			path: "/step/subscription",
			element: <SubscriptionChoice />,
		},
	},
	BILLING_CHOICE: {
		stepNb: 2,
		routerObj: {
			path: "/step/billing",
			element: <BillingChoice />,
		},
	},
	DELIVERY_FORMAT: {
		stepNb: 3,
		routerObj: {
			path: "/step/delivery-format",
			element: <DeliveryFormat />,
		},
	},
	ADDRESS_CHOICE: {
		stepNb: 4,
		routerObj: {
			path: "/step/address",
			element: <AddressChoice />,
		},
	},
	TIP_CHOICE: {
		stepNb: 5,
		routerObj: {
			path: "/step/tip",
			element: <TipChoice />,
		},
	},
	PAYMENT: {
		stepNb: 6,
		routerObj: {
			path: "/step/payment",
			element: <Payment />,
		},
	},
	ACTIVATE_CARD: {
		stepNb: null,
		routerObj: {
			path: "/moncadeau",
			element: <ActivateGift />,
		},
	},
	PAYMENT_SUCCESS: {
		stepNb: null,
		routerObj: {
			path: "/payment-success",
			element: <PaymentSuccess />, // Page Avec le renouvellement.
		},
	},
	PAYMENT_PENDING: {
		stepNb: null,
		routerObj: {
			path: "/payment-pending",
			element: <PaymentPending />, // même page que le succès mais le titre est marqué "votre commande est en cours de validation"
		},
	},
	PAYMENT_ERROR: {
		stepNb: null,
		routerObj: {
			path: "/payment-error",
			element: <PaymentError />, // UNE PAGE AVEC UN BOUTON RETENTER QUI RENVOIE SUR PAYMENT.JSX
		},
	},
	PAYMENT_VALIDATION: {
		stepNb: null,
		routerObj: {
			path: "/payment-validation",
			element: <PaymentValidation />, // PAGE FINALE (Vous pouvez profiter de inexploré)
		},
	},
	CHECK_SENDING: {
		stepNb: null,
		routerObj: {
			path: "/check-sending",
			element: <CheckSending />,
		},
	},
	ALREADY_SUBSCRIBED: {
		stepNb: null,
		routerObj: {
			path: "/already-subscribed",
			element: <AlreadySubscribed />,
		},
	},
	GIFT: {
		stepNb: null,
		routerObj: {
			path: "/offrir",
			element: <Offrir />,
		},
	},
	SUBSCRIBE: {
		stepNb: null,
		routerObj: {
			path: "/je-mabonne",
			element: <JeMabonne />,
		},
	},
	PAY_ORDER: {
		stepNb: null,
		routerObj: {
			path: "/payer-ma-commande",
			element: <PayOrder />,
		},
	},
	INEX_IOS: {
		stepNb: null,
		routerObj: {
			path: "/inexplore/ios",
			element: <RedirectMobile deviceType={"ios"} routeTo={"inexplore"} />,
		},
	},
	INEX_ANDROID: {
		stepNb: null,
		routerObj: {
			path: "/inexplore/android",
			element: <RedirectMobile deviceType={"android"} routeTo={"inexplore"} />,
		},
	},
	TV_IOS: {
		stepNb: null,
		routerObj: {
			path: "/inexploreTV/ios",
			element: <RedirectMobile deviceType={"ios"} routeTo={"inexploreTV"} />,
		},
	},
	TV_ANDROID: {
		stepNb: null,
		routerObj: {
			path: "/inexploreTV/android",
			element: <RedirectMobile deviceType={"android"} routeTo={"inexploreTV"} />,
		},
	}
};

const router = createBrowserRouter(Object.values(routesDefinitions).map((element) => element.routerObj));

// Layout pour appliquer GoogleTag sur toutes les pages
const Layout = () => (
	<>
	  <GoogleTag />
	  <Outlet />
	</>
  );
  
  // Mets le Layout comme wrapper dans les routes
  const routerWithTag = createBrowserRouter(
	[
	  {
		element: <Layout />, // Applique GoogleTag partout
		children: Object.values(routesDefinitions).map((element) => element.routerObj),
	  },
	]
  );
  
  // RoutesDefinitions : Gère les routes avec le RouterProvider
  const RoutesDefinitions = () => {
	return <RouterProvider router={routerWithTag} />;
  };

export default RoutesDefinitions;
