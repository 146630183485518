import { api } from "./rtkApi";

export const footerApi = api.injectEndpoints({
	endpoints: (build) => ({
		getFooter: build.query({
			query: () => {
				return {
					method: "GET",
					url: `/footer`,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetFooterQuery } = footerApi;
