import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleTag = () => {
  const location = useLocation(); // Récupère le chemin actuel
  useEffect(() => {
    // Charge le script gtag.js une seule fois au montage
    if (!window.gtag) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-1049373766';
      document.body.appendChild(script);

      const gtagScript = document.createElement('script');
      gtagScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        
        // Configuration pour les deux IDs
        gtag('config', 'AW-1049373766');
        gtag('config', 'GTM-MMG6LDXV');
        gtag('config', 'GT-MJWBD2F');
      `;
      document.body.appendChild(gtagScript);
    }
  }, []);

  useEffect(() => {
    // Envoie un événement à chaque changement de page
    if (window.gtag) {
      const page_path = location.pathname;
      window.gtag('config', 'AW-1049373766', { page_path });
      window.gtag('config', 'GTM-MMG6LDXV', { page_path });
      window.gtag('config', 'GT-MJWBD2F', { page_path });

      // Envoi de l'event personnalisé "page_view"
      window.gtag('event', 'page_view', {
        page_path: location.pathname,
        page_title: document.title,
      });

      // Envoie l'event complete uniquement sur /payment-success
      if (location.pathname === '/payment-success') {
        window.gtag('event', 'conversion', {
          send_to : 'AW-1049373766/7pnECKHR3qMaEMbYsPQD',
          value: 1.0, // Exemple de valeur, remplace par la bonne
          currency: 'EUR',
          transaction_id: '' // Exemple d'ID de transaction
        });
      }
    }
  }, [location]);

  return null;
};

export default GoogleTag;