import React from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import InexButton from "../components/InexButton";
import BasicScreen from "../components/BasicScreen";
import {
	useRedirectToAlreadySubscribed,
	useRedirectToCountryChoice,
	useRedirectToLanding,
} from "../hooks/subscription";
import { useDispatch, useSelector } from "react-redux";
import {
	addSubscription,
	getSelectedCountry,
	getSelectedSubscriptions,
	getTunnel,
	removeSubscription,
	setTunnel,
} from "../store/reducers/subscribeSlice";
import { useGetTypeDAbonnementsQuery } from "../store/api/subscribeTunnel";
import SubscriptionTypeCard from "../components/subscriptionChoice/SubscriptionTypeCard";
import InexSwitch from "../components/subscriptionChoice/InexSwitch";
import { useIsMobile } from "../hooks/responsive";
import InexCard from "../components/InexCard";
import { routesDefinitions } from "./RoutesDefinitions";
import { useStep } from "../hooks/useStep";
import { useGetAbosQuery } from "../store/api/user";
import { getAuthToken, getInreesId } from "../store/reducers/authSlice";
import { useLogUserIn } from "../hooks/auth";
import { useNavigate } from "react-router";

const SubscriptionChoice = ({ ...props }) => {
	useRedirectToLanding();
	useRedirectToCountryChoice();
	useRedirectToAlreadySubscribed();

	useLogUserIn();

	const dispatch = useDispatch();
	React.useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has("renouv")) dispatch(setTunnel(3));
		
	}, []);

	const stepNb = useStep("SUBSCRIPTION_CHOICE");
	const isMobile = useIsMobile();

	const tunnel = useSelector(getTunnel);
	const selectedCountry = useSelector(getSelectedCountry);

	const now = new Date();
	const ts = `${now.getFullYear()}-${now.getMonth()}-${now.getDay()} ${now.getHours()}:00:00`;

	const [subscriptionTypes, setSubscriptionTypes] = React.useState(null);

	const { data, isFetching } = useGetTypeDAbonnementsQuery(
		{
			tunnel,
			zone: selectedCountry?.zone,
			ts,
		},
		{
			skip: selectedCountry == null || tunnel === null,
		}
	);

	React.useEffect(() => {
		if (data) {
			setSubscriptionTypes(data.abos);
		}
	}, [data]);

	const authToken = useSelector(getAuthToken);
	const inreesId = useSelector(getInreesId);

	const { data: dataAbos } = useGetAbosQuery(
		{
			authToken,
			idinexplore: inreesId,
		},
		{
			skip: !authToken || !inreesId,
		}
	);

	const subscriptions = useSelector(getSelectedSubscriptions);

	
	const addSubscriptionToStore = (value, isGiftCard) => {
		if (value.idabo === 2) {
			dispatch(addSubscription(subscriptionTypes[0]));
		}
		dispatch(addSubscription(value));
	};

	const removeSubscriptionFromStore = (value, isGiftCard) => {
		if (value.idabo === 2) {
			dispatch(removeSubscription(subscriptionTypes[0]));
		}
		dispatch(removeSubscription(value));
	};

	const availableSubscriptions = React.useMemo(() => {
		if (tunnel === 1) return subscriptionTypes;
		return subscriptionTypes?.filter((element) => {
			if (element.idabo === 1 && dataAbos?.abos?.digital == 1) return false;
			if (element.idabo === 3 && dataAbos?.abos?.mag == 1) return false;
			if (element.idabo === 2 && dataAbos?.abos?.tv == 1) return false;
			return true;
		});
	}, [subscriptionTypes, dataAbos]);

	const isGiftCard = React.useMemo(() => {
		return tunnel === 1;
	}, [tunnel])

	const isAllSelected = React.useMemo(() => {
		return subscriptions?.length === availableSubscriptions?.length;
	}, [subscriptions, availableSubscriptions]);

	const handleSelectAll = React.useCallback(() => {
		availableSubscriptions?.forEach((e) => {
			isAllSelected ? removeSubscriptionFromStore(e, isGiftCard) : addSubscriptionToStore(e, isGiftCard);
		});
	}, [isAllSelected, availableSubscriptions, isGiftCard]);

	React.useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		if (urlParams.has("selectedAbos")) {
			try {
				const selectedAbos = JSON.parse(urlParams.get("selectedAbos"));
				setGetParameters(selectedAbos);
			} catch (e) {
				console.error(e);
			}
		}
	}, []);

	const [getParameters, setGetParameters] = React.useState(null);

	React.useEffect(() => {
		if (getParameters !== null && availableSubscriptions) {
			let selectedAbos = undefined;
			if (typeof getParameters === "number") selectedAbos = [getParameters];
			else if (Array.isArray(getParameters)) selectedAbos = getParameters;
			else {
				setGetParameters(null);
				return;
			}
			availableSubscriptions.forEach((element) => {
				if (selectedAbos.findIndex((e) => e === element.idabo) !== -1) addSubscriptionToStore(element);
			});
			setGetParameters(null);
		}
	}, [getParameters, availableSubscriptions]);

	const isTVSelected = React.useMemo(() => {
		return subscriptions?.findIndex((e) => e.idabo === 2) !== -1;
	}, [subscriptions]);

	return (
		<BasicScreen
			step={stepNb}
			nextPath={routesDefinitions["BILLING_CHOICE"].routerObj.path}
			canGoNext={!(!subscriptions || subscriptions.length === 0)}
		>
			{availableSubscriptions?.length > 0 && (
				<Typography
					variant={"h4"}
					textAlign={"left"}
					color={"secondary"}
					sx={{
						fontFamily: "Branding Bold",
						fontSize: 20,
					}}
				>
					{tunnel === 3 ? "Réabonnez-vous à une ou plusieurs formules" : "Composez votre panier en ajoutant un ou plusieurs abonnements :"}
				</Typography>
			)}
			<Grid container spacing={3} my={3}>
				{!isFetching && availableSubscriptions?.length > 0 && (
					<Grid item xs={12}>
						<Box
							sx={{
								background: "white",
							}}
						>
							<InexCard
								selected={isAllSelected}
								onClick={handleSelectAll}
								sx={{
									padding: 0,
									paddingTop: 0,
									paddingBottom: 0,
									display: "flex",
									alignItems: "center",
									flexDirection: "row",
									backgroundColor: "rgba(22,42,65,0.03)",
									"&:hover": {
										cursor: "pointer",
									},
								}}
							>
								<Grid item xs={10} sm={6} md={6} container direction={"row"} wrap={"nowrap"} alignItems={"center"}>
									<Box
										width={isMobile ? "120px" : "150px"}
										minWidth={"95px"}
										height={"100%"}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<InexSwitch
											checked={isAllSelected}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												handleSelectAll();
											}}
										/>
									</Box>
									<Box
										sx={{
											width: "100%",
											height: "100%",
											display: "flex",
											alignItems: "center",
											flexDirection: "column",
											padding: (theme) => `${theme.spacing(isMobile ? 2 : 3)} ${theme.spacing(isMobile ? 0 : 4)}`,
										}}
									>
										<Typography
											variant={"h4"}
											textAlign={"left"}
											color={"secondary"}
											sx={{
												width: "100%",
												fontFamily: "Branding Bold",
												fontSize: 24,
											}}
										>
											{"Tout sélectionner"}
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={1} sm={3} md={3} container />
								<Grid item xs={1} sm={3} md={3} container />
							</InexCard>
						</Box>
					</Grid>
				)}

				{isFetching ? (
					<>
						<Grid item xs={12}>
							<Skeleton
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								width={"100%"}
								height={"133px"}
								animation={"pulse"}
							/>
						</Grid>
						<Grid item xs={12}>
							<Skeleton
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								width={"100%"}
								height={"133px"}
								animation={"pulse"}
							/>
						</Grid>
						<Grid item xs={12}>
							<Skeleton
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								width={"100%"}
								height={"133px"}
								animation={"pulse"}
							/>
						</Grid>
					</>
				) : (
					subscriptionTypes?.map((subType, index) => {
						const isSelected = subscriptions?.findIndex((e) => e.idabo === subType.idabo) !== -1;
						const isAvailable = availableSubscriptions.findIndex((e) => e.idabo === subType.idabo) !== -1;

						return (
							<Grid key={index} item xs={12}>
								{isTVSelected && index === 0 && (
									<Typography
										sx={{
											color: (theme) => theme.palette.secondary.light,
											zIndex: 100000,
											marginBottom: 1,
										}}
									>
										Option est désactivée car inclue dans Inexploré TV + digital
									</Typography>
								)}
								<SubscriptionTypeCard
									obj={subType}
									selected={isSelected && !(isTVSelected && index === 0)}
									disabled={!isAvailable || (isTVSelected && index === 0)}
									onClick={() => {
										isSelected ? removeSubscriptionFromStore(subType, isGiftCard) : addSubscriptionToStore(subType, isGiftCard);
									}}
								/>
							</Grid>
						);
					})
				)}
			</Grid>
		</BasicScreen>
	);
};

export default SubscriptionChoice;
