const entities = {
	"&Agrave;": "À",
	"&#192;": "À",
	"&Aacute;": "Á",
	"&#193;": "Á",
	"&Egrave;": "È",
	"&#200;": "È",
	"&Eacute;": "É",
	"&#201;": "É",
	"&Ecirc;": "Ê",
	"&#202;": "Ê",
	"&Euml;": "Ë",
	"&#203;": "Ë",
	"&Igrave;": "Ì",
	"&#204;": "Ì",
	"&Iacute;": "Í",
	"&#205;": "Í",
	"&Icirc;": "Î",
	"&#206;": "Î",
	"&Iuml;": "Ï",
	"&#207;": "Ï",
	"&Ograve;": "Ò",
	"&#210;": "Ò",
	"&Oacute;": "Ó",
	"&#211;": "Ó",
	"&Ocirc;": "Ô",
	"&#212;": "Ô",
	"&Ouml;": "Ö",
	"&#214;": "Ö",
	"&Ugrave;": "Ù",
	"&#217;": "Ù",
	"&Uacute;": "Ú",
	"&#218;": "Ú",
	"&Ucirc;": "Û",
	"&#219;": "Û",
	"&Uuml;": "Ü",
	"&#220;": "Ü",
	"&Ccedil;": "Ç",
	"&#199;": "Ç",
	"&egrave;": "è",
	"&#232;": "è",
	"&eacute;": "é",
	"&#233;": "é",
	"&ecirc;": "ê",
	"&#234;": "ê",
	"&euml;": "ë",
	"&#235;": "ë",
	"&igrave;": "ì",
	"&#236;": "ì",
	"&iacute;": "í",
	"&#237;": "í",
	"&icirc;": "î",
	"&#238;": "î",
	"&iuml;": "ï",
	"&#239;": "ï",
	"&ograve;": "ò",
	"&#242;": "ò",
	"&oacute;": "ó",
	"&#243;": "ó",
	"&ocirc;": "ô",
	"&#244;": "ô",
	"&ouml;": "ö",
	"&#246;": "ö",
	"&ugrave;": "ù",
	"&#249;": "ù",
	"&uacute;": "ú",
	"&#250;": "ú",
	"&ucirc;": "û",
};

export const decodeString = (string) => {
	if (!string) return "";
	let tmp = string.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
		return String.fromCharCode(charCode);
	});
	return tmp.replace(/&[^;]+;/g, (entity) => entities[entity] || entity);
};
