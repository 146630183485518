import { apiBase, tunnelApiBase } from "src/config/index";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: tunnelApiBase,
		prepareHeaders: async (headers) => {
			return headers;
		},
	}),
	keepUnusedDataFor: 0.001,

	endpoints: () => ({}),
	reducerPath: "api",
	tagTypes: ["Pays", "TypeDAbonnements", "CycleAbonnement", "Addresses"],
});
