import React from "react";
import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import AboStepper from "./AboStepper";
import { useIsMobile, useIsTablet } from "../hooks/responsive";
import SubscriptionDetail from "./SubscriptionDetail";

const Panel = styled(Box)(({ theme }) => {
	const isMobile = useIsMobile();
	return {
		background: "white",
		height: isMobile ? undefined : "100%",
		boxShadow: "0px 0 30px lightgrey;",
		overflowY: "hidden",
		zIndex: theme.zIndex.drawer,
		margin: theme.spacing(isMobile ? 2 : 0),
	};
});

const BasicScreen = ({
	step = 0,
	nextPath,
	canGoNext = false,
	nextStepButtonLabel,
	children,
	withPanel = true,
	...props
}) => {
	const theme = useTheme();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	// Scroll to top when rendered for the first time.
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Grid
			container
			direction={"row"}
			width={"100%"}
			sx={{
				overflow: "hidden",
			}}
			{...props}
		>
			<Grid item xs={12} md={8} lg={9} px={isTablet ? 1 : 4} py={isMobile ? 3 : 4}>
				<AboStepper currentStep={step} />
				<Grid container px={isMobile ? 1 : 5} pt={isMobile ? 2 : 5} pb={isMobile ? 2 : 5}>
					{children}
				</Grid>
			</Grid>
			{withPanel === true && (
				<Grid item xs={12} md={4} lg={3}>
					<Panel>
						<Grid
							container
							py={isMobile ? 4 : 7}
							px={isMobile ? 3 : 4}
							direction={"column"}
							sx={{
								paddingTop: theme.spacing(isMobile ? 5 : 7),
								paddingBottom: theme.spacing(isMobile ? 5 : 7),
							}}
						>
							<SubscriptionDetail
								currentStep={step}
								nextPath={nextPath}
								canGoNext={canGoNext}
								nextStepButtonLabel={nextStepButtonLabel}
							/>
						</Grid>
					</Panel>
				</Grid>
			)}
		</Grid>
	);
};

export default BasicScreen;
