import React from "react";
import InexCard from "../InexCard";
import { Box, Divider, Grid, styled, Typography } from "@mui/material";
import { useIsMobile } from "../../hooks/responsive";
import SubscriptionTypeIcon from "./SubscriptionTypeIcon";

import earthIcon from "../../resources/images/svg/subscriptionChoice/earth@1,5x.svg";
import appIcon from "../../resources/images/svg/subscriptionChoice/smartphoneSVG@1,5x.svg";
import tvIcon from "../../resources/images/svg/subscriptionChoice/monitor@1,5x.svg";
import magIcon from "../../resources/images/svg/subscriptionChoice/book@1,5x.svg";
import InexSwitch from "./InexSwitch";
import PriceFormater from "../PriceFormater";
import {getTunnel} from "../../store/reducers/subscribeSlice";
import {useSelector} from "react-redux";

const Container = styled(Box)(({ theme }) => {
	const isMobile = useIsMobile();
	return {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		flexWrap: "wrap",
		paddingTop: theme.spacing(isMobile ? 0 : 5),
		paddingBottom: theme.spacing(isMobile ? 0 : 5),
	};
});

const SubscriptionTypeCard = ({ obj, selected = false, disabled, onClick, ...props }) => {

	const isMobile = useIsMobile();

	const isDigital = React.useMemo(() => {
		return obj.idabo === 1;
	}, obj);

	const isTV = React.useMemo(() => {
		return obj.idabo === 2;
	}, obj);

	const isMag = React.useMemo(() => {
		return obj.idabo === 3;
	}, obj);

	const tunnel = useSelector(getTunnel);
	const isGiftCard = React.useMemo(() => {
		return tunnel === 1;
	}, [tunnel])

	return (
		<InexCard
			selected={selected}
			disabled={disabled}
			sx={{
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0,
				...props.sx,
			}}
			onClick={!disabled ? onClick : undefined}
			{...props}
		>
			<Grid container direction={"row"} sx={{ height: "100%" }}>
				<Grid item xs={12} sm={6} md={6} container direction={"row"} wrap={"nowrap"} alignItems={"center"}>
					<Box
						width={isMobile ? "120px" : "150px"}
						minWidth={"95px"}
						height={"100%"}
						sx={{
							backgroundColor: "rgba(22,42,65,0.03)",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<InexSwitch checked={selected} onClick={onClick} />
					</Box>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
							backgroundColor: isMobile ? "rgba(22,42,65,0.03)" : undefined,
							padding: (theme) => `${theme.spacing(isMobile ? 2 : 3)} ${theme.spacing(isMobile ? 0 : 4)}`,
						}}
					>
						<Typography
							variant={"h4"}
							textAlign={"left"}
							color={"secondary"}
							sx={{
								width: "100%",
								fontFamily: "Branding Bold",
								fontSize: 24,
							}}
						>
							{obj.titre}
						</Typography>
						<Typography
							textAlign={"left"}
							color={"grey.500"}
							mt={1}
							sx={{
								width: "100%",
								height: "100%",
								fontFamily: "Branding SemiBold",
								fontSize: 16,
								paddingRight: (theme) => theme.spacing(1),
							}}
						>
							{isTV && (
								<Typography
									sx={{
										color: (theme) => theme.palette.primary.light,
										fontFamily: "Branding Bold",
									}}
								>
									{"Notre formule 360° ! "}
								</Typography>
							)}
							{obj.description}
						</Typography>
					</Box>
					{!isMobile && (
						<Divider
							variant={"fullWidth"}
							orientation={"vertical"}
							sx={{
								height: "65%",
							}}
						/>
					)}
				</Grid>
				<Grid
					item
					xs={6}
					sm={3}
					md={3}
					container
					direction={"row"}
					wrap={"nowrap"}
					alignItems={"center"}
					py={isMobile ? 3 : undefined}
				>
					<Container>
						{obj.web === 1 && <SubscriptionTypeIcon icon={<img src={earthIcon} alt={"earthIcon"} />} text={"Web"} />}
						{obj.mobile === 1 && <SubscriptionTypeIcon icon={<img src={appIcon} alt={"appIcon"} />} text={"Appli"} />}
						{obj.tv === 1 && <SubscriptionTypeIcon icon={<img src={tvIcon} alt={"tvIcon"} />} text={"TV"} />}
						{obj.papier === 1 && (
							<SubscriptionTypeIcon icon={<img src={magIcon} alt={"magIcon"} />} text={"Mag. papier"} />
						)}
					</Container>
					<Divider
						variant={"fullWidth"}
						orientation={"vertical"}
						sx={{
							height: "65%",
						}}
					/>
				</Grid>
				<Grid
					item
					xs={6}
					md={3}
					sm={3}
					container
					justifyContent={"center"}
					alignItems={"center"}
					py={isMobile ? 3 : undefined}
				>
					<PriceFormater
						price={obj.frequence.montant}
						period={obj.frequence.titre}
						withTitle={
							isMag && !isGiftCard && false ? (
								<Typography
									sx={{
										color: (theme) => theme.palette.primary.light,
										textAlign: "center",
										fontSize: 14,
									}}
								>

									{"Recevez dès le 25 décembre l'almanach spirituel en cadeau 🎁"}
								</Typography>
							) : (
								true
							)
						}
					/>
				</Grid>
			</Grid>
		</InexCard>
	);
};

export default SubscriptionTypeCard;
