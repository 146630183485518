import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const TipArgumentCard = ({ icon, title, subTitle, ...props }) => {
	return (
		<Grid container direction={"column"}>
			<Grid item xs={12}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "70px",
						height: "70px",
						borderRadius: "35px",
						overflow: "hidden",
						backgroundColor: "#F3C84F",
						opacity: 0.5,
					}}
				>
					{icon}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Typography
					textAlign={"left"}
					color={"secondary"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						marginTop: (theme) => theme.spacing(1),
					}}
				>
					{title}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding Medium",
						fontSize: 16,
						marginTop: (theme) => theme.spacing(1),
					}}
				>
					{subTitle}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default TipArgumentCard;
